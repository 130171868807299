@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[data-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
[data-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}
.profile-usertitle-name {
  text-align: center;
  color: #5a7391;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}
.profile-usertitle-email {
  text-align: center;
  color: #5b9bd1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
.avatar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.shlok {
  font-family: "Noto Sans";
  font-weight: bold;
  color: #ff8400;
  text-align: center;
  font-size: 3vw;
}

.shlok-subtitle {
  font-family: "Noto Sans";
  color: #ff8000;
  text-align: center;
  font-size: 2.5vw;
}

@media only screen and (min-width: 600px) {
  .shlok {
    font-size: 2vw;
  }
  .shlok-subtitle {
    font-size: 1.5vw;
  }
}

.jumbotron {
  background-color: #ffa43423 !important;
  color: #b91e10;
}

.card-text {
  font-family: "Ubuntu";
  color: #717171;
}

.section {
  color: #ff8000;
  font-size: 2rem;
}

.subtitle {
  font-family: "Noto Sans";
  color: #ff8000;
  text-align: center;
  font-size: 1.2em;
}

.subtitle-active {
  font-family: "Noto Sans";
  color: #972c02;
  text-align: center;
  font-size: 1.2em;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.display-linebreak {
  white-space: pre-line;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

